const updateText = `Update features`
const deleteText = `Delete features`
const exportText = `Export features`

const prodEditLayers = {
  pavingURLs: [
    // Paving
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Plan/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Moratorium/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Plan_-_Complete/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/PCI/FeatureServer/0',
  ],
  utilityURLs: [
    // Utility
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Gas/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Electric/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Communications/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Water/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Recycled_Water/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Sewer_Force/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Sewer_Gravity/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Hazardous_Material/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Stormwater/FeatureServer/0',
  ],
  projectURLs: [
    // Projects
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project_-_Future/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project_-_Complete/FeatureServer/0',
  ],
  intersectionURLs: [
    // Intersections
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Alerts/FeatureServer/0',
  ]
}
const stageEditLayers = {
  pavingURLs: [
    // Paving
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Plan_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Moratorium_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Paving_Plan_Test_-_Complete/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/PCI_Test/FeatureServer/0',
  ],
  utilityURLs: [
    // Utility
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Gas_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Electric_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Communications_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Water_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Recycled_Water_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Sewer_Force_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Sewer_Gravity_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Hazardous_Material_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Stormwater_Test/FeatureServer/0',
  ],
  projectURLs: [
    // Projects
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project_Test/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project_Test_-_Future/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Project_Test_-_Complete/FeatureServer/0',
  ],
  intersectionURLs: [
    // Intersections
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Alerts_Test/FeatureServer/0',
  ]
}
const boundaryLayers = {
  boundaryURLs: [
    // The service area handling is done separately below
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Service_Boundary/FeatureServer/0',
    //'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/ServiceBoundaryTest/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/County/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/City_or_Town/FeatureServer/0',
    'https://services3.arcgis.com/gxsXHdk0MOVUX9bl/arcgis/rest/services/Census_Designated_Place_or_Military/FeatureServer/0',
  ]
}

const hiddenFields = [
  "OBJECTID",
  "ObjectId", // Appears both ways
  "MemberId",
  // "MemberName",
  // "UploadDate", // Drawn items will not have an upload date
  "GlobalID",
  "Shape__Length",
  "APICreator",
  "APIEditor",
  "Zip",
  "State",
  "Country",
  "City",
  "County",
  "TimeSlideDate",
  "VisibleAreas",
  "ConflictMemberId",
  "AlertOwnerMemberId",
  "AlertOwnerName",
  // "last_edited_date",
  // "last_edited_user",
  // "created_date",
  // "created_user"
]

const nonInteractiveFields = [
  // "City",
  // "County",
  "MemberName",
  //"MemberId",
  "UploadDate",
  "ConflictOrgName",
  "OwnerLayer",
  "ConflictLayer",
  //"Country",
]

// List of layers PMs can edit
const projectManagerEditLayers = [
  'Paving Moratorium Test',
  'Paving Moratorium',
  'Paving Plan Test',
  'Paving Plan Test - Complete',
  'Paving Plan',
  'Paving Plan - Complete',
  'Project Test',
  'Project Test - Complete',
  'Project Test - Future',
  'Project',
  'Project - Complete',
  'Project - Future'
]

// List of layers that show up in the org quickselect
const orgQuickLayers = [
  'Paving Moratorium Test',
  'Paving Moratorium',
  'Paving Plan Test',
  'Paving Plan Test - Complete',
  'Paving Plan',
  'Paving Plan - Complete',
  'Project Test',
  'Project Test - Complete',
  'Project Test - Future',
  'Project',
  'Project - Complete',
  'Project - Future',
  'PCI Test',
  'PCI ',
  'Alerts Test',
  'Alerts'
]

// List of layers that show up in the other org quickselect
const otherOrgQuickLayers = [
  'Paving Moratorium Test',
  'Paving Moratorium',
  'Paving Plan Test',
  'Paving Plan',
  'Project Test',
  'Project Test - Future',
  'Project',
  'Project - Future'
]

const typeConditionMap = {
  integer: ["is equal", "is not equal", "is greater than", "is less than"],
  double: ["is equal", "is not equal", "is greater than", "is less than"],
  "small-integer": ["is equal", "is not equal", "is greater than", "is less than"],
  string: ["contains", "does not contain", "is equal", "is not equal"],
  date: ["is equal", "is not equal", "is after", "is before"],
}

// Get the current year for calc
const currentYear = new Date().getFullYear()
const layerFilterMap = [
  {
    matchText: ["%28Paving_Plan_Test%29", "%28Paving_Plan%29"],
    filter: `((PaveComplete <> 'Yes' OR PaveComplete = NULL) AND (\
    (PaveDate >= CURRENT_TIMESTAMP - 730) OR \
    (PaveDate = NULL AND YearStart >= ${currentYear - 2}) OR \
    (PaveDate = NULL AND YearStart = NULL AND created_date >= CURRENT_TIMESTAMP - 730) \
    ))`
  },
  {
    matchText: ["%28Paving_Plan_Test_-_Complete%29", "%28Paving_Plan_-_Complete%29"],
    filter: `(\
    (PaveDate >= CURRENT_TIMESTAMP - 3650) OR \
    (PaveDate = NULL AND last_edited_date >= CURRENT_TIMESTAMP - 3650)
    )`
  },
  { // Don't show moratorium lines if the date has passed, but DO show them if value is NULL
    matchText: ["%28Paving_Moratorium_Test%29", "%28Paving_Moratorium%29"],
    filter: `(\
    (MorEnd >= CURRENT_TIMESTAMP OR MorEnd = NULL)\
    )`
  },
  {
    matchText: ["%28Project_Test%29", "%28Project%29"],
    filter: `(\
      (Status = 'Planned' OR Status = NULL) AND \
      (YearStart = NULL OR YearStart >= ${currentYear - 2})\
    )`
  },
  { // THESE LINES AREN'T SHOWING EVEN WITH THE FILTERS REMOVED
    // BUT THINGS BROKE WHEN WE REMOVED THEM, SO KEEP EM
    matchText: ["%28Project_Test_-_Complete%29", "%28Project_-_Complete%29"],
    filter: `(YearComplete = NULL OR YearComplete >= ${currentYear - 3})`
  },
  // {
  //   matchText: ["Member_Project_Test_-_Future/FeatureServer", "Member_Project_-_Future/FeatureServer"],
  //   filter: `(ProjComplete <> 'Yes' AND YearComplete = NULL)`
  // }
]

const generateFilter = (matchInput) => {
  for (let i = 0; i < layerFilterMap.length; i++){
    let match = false
    for (let j = 0; j < layerFilterMap[i]['matchText'].length; j++){
      // We need to make these all accommodate the new view syntax
      if (matchInput.indexOf(layerFilterMap[i]['matchText'][j]) !== -1){
        match = true
        break
      }
    }
    if (match){
      return layerFilterMap[i]['filter']
    }
  }
  return ""
}

const decodeCookie = (string) => {
  // If cookie is already base64 decoded, don't do anything
  let decoded = string
  try {
    if (/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(string)){
      decoded = JSON.parse(atob(string))
    }
  } catch (err){
    // This failed!
    //console.log("DECODE FAILED", err)
  }
  return decoded
}

// These are layers that need to be updated together
const layerBinding = [
  // Test layers
  ["Paving Plan Test - Complete", "Paving Plan Test"],
  ["Project Test - Complete", "Project Test - Future", "Project Test"],
  // Prod layers
  ["Paving Plan", "Paving Plan - Complete"],
  ["Project", "Project - Future", "Project - Complete"]
]

// Slugify for our lockfiles
const slugify = (string) => {
  return string.replace(/[\W_]+/g,"")
}

// Parses the true layer name out of the URL
const getTrueLayerName = (url) => {
  try {
    let trueLayerName = url.match(/%28%28(.*?)%29%29/gm)
    trueLayerName = trueLayerName[0].replace(/_/g, " ").replace(/%28%28/g, "").replace(/%29%29/g, "")
    return trueLayerName
  } catch (err){
    console.log("ERR", err)
    // We got some problems if this fails, but better than breaking the app
    return false
  }
}

const loginFlow = (redirectUri) => {
  window.open('https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=w56p2ITFj56WVCjB&response_type=token&expiration=20160&redirect_uri=' + window.encodeURIComponent(redirectUri), 'oauth-window', 'height=400,width=600,menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes')
}

async function checkExportReady(domain, user, paverToken, jobId, exportItemId) {
  let finished = false
  while (!finished){
    const fetchURL = `https://${domain}/sharing/rest/content/users/${user}/items/${exportItemId}/status`
    // console.log("fetchURL", fetchURL, paverToken)
    let resp = await fetch(fetchURL, {
      method: 'post',
      body: new URLSearchParams({
        f: "json",
        token: paverToken,
        jobType: "export",
        jobId: jobId
      })
    })
    resp = await resp.json()
    // console.log("RESP", resp)
    if (resp.status === "completed" || resp.status === "failed"){
      finished = true
      return resp
    }
    // Wait a second before retrying
    await new Promise(resolve => setTimeout(resolve, 1000))
  }
}

const convertFieldToArcGIS = (fieldType) => {
  switch (fieldType){
    case "string":
      return "esriFieldTypeString"
    case "integer":
      return "esriFieldTypeInteger"
    case "double":
      return "esriFieldTypeDouble"
    case "date":
      return "esriFieldTypeDate"
    case "small-integer":
      return "esriFieldTypeSmallInteger"
    default:
      return "esriFieldTypeString"
  }
}

// Apparently imports are global! We can change this and check it as needed across the app
const globalState = {
  selectionInProgress: false,
  dropModalOpen: false
}

module.exports = {globalState, updateText, deleteText, exportText, hiddenFields, nonInteractiveFields, typeConditionMap, generateFilter, layerBinding, projectManagerEditLayers, orgQuickLayers, otherOrgQuickLayers, decodeCookie, prodEditLayers, stageEditLayers, boundaryLayers, slugify, getTrueLayerName, loginFlow, checkExportReady, convertFieldToArcGIS}